import React, { PureComponent } from 'react'
import Layout from '../molecules/layout'
import { Wrapper, SEO } from '@renderbus/common/components'

import WuhanSlogan1 from '../images/index/wuhan-slogan1.png'
import WuhanSlogan2 from '../images/index/wuhan-slogan2.png'
import WuhanImg from '../images/index/wuhan-img.png'
import SloganImg from '../images/return50ps/slogan.png'
import Title1 from '../images/return50ps/title1.png'
import Title2 from '../images/return50ps/title2.png'
import Title3 from '../images/return50ps/title3.png'

import {
  PageContainer,
  BannerWrapper,
  BannerContainer,
  WuhanContainer,
  Line,
  Text,
  FightingImg,
  Block,
  Slogan,
  Title,
  Tips,
} from './return50ps.atom'

class Return50ps extends PureComponent {
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='众志成城,共克时艰,Renderbus渲染费返还50%'
          keywords=''
          description='Renderbus国内影视动画用户全国一级响应期间,所有镜头的渲染费用均可返还50%,众志成城,共克时艰'
        />
        <PageContainer>
          <Wrapper>
            <BannerWrapper>
              <BannerContainer>
                <WuhanContainer>
                  <img src={WuhanSlogan1} alt='众志成城 共克时艰' />
                  <img src={WuhanSlogan2} alt='渲染给力 我们挺你' />
                  <Line />
                  <Text>国内影视动画用户 | 全国一级响应期间</Text>
                  <Text style={{ color: '#ffae00' }}>所有镜头的渲染费用均可返还50%</Text>
                </WuhanContainer>
                <FightingImg src={WuhanImg} alt='武汉加油' />
              </BannerContainer>
            </BannerWrapper>
            <Block>
              <div>
                <Text style={{ marginBottom: '8px' }}>致尊敬的瑞云渲染用户：</Text>
                <Text style={{ marginBottom: '8px' }}>
                  2020年，注定是不平凡的一年，不仅是因为一场来势汹汹的新型冠状病毒疫情正在延烧，更是因为举国上下万众一心，在艰难的时刻用积极的行动，抗击疫情，铸造希望。
                </Text>
                <Text>
                  面对这不期而遇的新挑战、突如其来的新考验，瑞云人愿与你共奋战、同进退。让我们心手相牵，坚定信心，同舟共济，打赢这场无硝烟的抗疫之战。
                </Text>
              </div>
              <Slogan>
                <img src={SloganImg} alt='武汉加油' />
              </Slogan>
            </Block>
            <Title>
              <img src={Title1} alt='活动时间' />
            </Title>
            <Block>
              <Text>1月23日 - 2月29日 | 全国一级响应期间 (活动截止日期暂定，视具体情况调整)</Text>
            </Block>
            <Title>
              <img src={Title2} alt='活动对象' />
            </Title>
            <Block>
              <Text>Renderbus影视动画国内用户(青云平台用户除外)</Text>
            </Block>
            <Title>
              <img src={Title3} alt='活动规则' />
            </Title>
            <Block>
              <Text>
                1、活动期间，国内影视动画用户(除青云平台用户外)渲染的所有镜头的费用均可以代金券形式返还50%
              </Text>
              <Text>
                2、活动结束后一周内，用户可通过“Renderbus作业控制台”微信小程序提出申请，审核通过后可返还相应的代金券
              </Text>
            </Block>
            <Tips>本次活动最终解释权归深圳市瑞云科技有限公司所有</Tips>
          </Wrapper>
        </PageContainer>
      </Layout>
    )
  }
}
export default Return50ps
